import { I18n } from 'aws-amplify'

import LogOutError from './logOutError'

export class NoRedirectError extends LogOutError {
    readonly title: string
    readonly description: string

    constructor() {
        const title = I18n.get('No redirect provided.')
        const description = I18n.get('To login, please first navigate to the platform you need to access')

        super(`${title} ${description}`)
    }
}

export default NoRedirectError
