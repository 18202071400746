import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export class ExpiredSessionError extends LogOutError {
    constructor() {
        super(I18n.get('User session has expired.'))
    }
}

export default ExpiredSessionError
