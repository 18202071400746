import React from 'react'
import { I18n } from 'aws-amplify'
import {
    View, Heading, Button, Image
} from '@aws-amplify/ui-react'

const userAlert = ({title, description, signOut}) => {
    return <View id="verify-email-modal" style={{left: '5vw', top: '1vh', backgroundColor: 'white'}}> 
        <View textAlign="center">
            <Image
                alt="Webgains logo"
                src="https://www.webgains.com/public/wp-content/themes/webgains-updated/img/logo-new-color-s100.svg"
                width="100px"
                height="100px"
                style={{margin: '0 0 5vh 0'}}
            />
        </View >
        <Heading level={3} textAlign="center">{title}</Heading>
        <View className="subheader" textAlign="center" style={{padding: '10vh 0'}}>
            {description}
        </View>
        <View textAlign="center">
            <Button
                variation="primary"
                isFullWidth={true}
                fontWeight="normal"
                onClick={signOut}
                size="small">
                {I18n.get('Back to Sign In')}
            </Button>
        </View>
    </View>

}

export default userAlert
