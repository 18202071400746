import '@aws-amplify/ui-react/styles.css'

import { AmplifyUser } from '@aws-amplify/ui'
import {
    Loader,
    useAuthenticator,
    withAuthenticator,
} from '@aws-amplify/ui-react'
import {
    Amplify,
    Auth,
    Hub,
} from 'aws-amplify'
import React, { useEffect, useState } from 'react'

import * as components from './components'
import InvalidUserAlert from './components/invalidUserAlert'
import UserAlert from './components/userAlert'
import config from './config/amplify'
import { STORE_KEY_AUTH } from './config/constants'
import * as formFields from './formFields'
import * as services from './services'
import {
    ExpiredPasswordError,
    ExpiredSessionError,
    NoAccessError,
    NoMfaError,
    VerifyEmailError,
    getLanguage,
    getRedirectRoute,
    getUser,
    mustHaveMFA,
    sendVerificationEmail,
    setupInternationalization,
    storeLanguage
} from './utils'
import { deleteCookie, setCookie } from './utils/cookies'
// eslint-disable-next-line import/named
import { ApiUser, getTokenPayload, ROLE_NO_LOGIN } from './utils/getUser'

setupInternationalization()

export function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload
        if (event === 'signOut') {
            const language = getLanguage()
            localStorage.clear()
            deleteCookie(STORE_KEY_AUTH)
            storeLanguage(language)
        }
    })
}

Amplify.configure(config)
listenToAutoSignInEvent()

interface middlewareProps {
  cognitoUser: AmplifyUser & {preferredMFA?: string},
  user: ApiUser | null,
}

type redirectProps = Pick<middlewareProps, 'cognitoUser'>;

export function handleRedirect({ cognitoUser }: redirectProps) {
    const win: Window = window
    const userSession = cognitoUser.getSignInUserSession()
    const idToken = userSession.getIdToken().getJwtToken()
    const { exp } = getTokenPayload(idToken)
    const route = getRedirectRoute()
    setCookie(STORE_KEY_AUTH, JSON.stringify({access_token: idToken}), new Date(exp * 1000))
    win.location.replace(`${route}`)
}

export async function middleware({
    cognitoUser, user
}: middlewareProps): Promise<boolean> {
    const userSession = cognitoUser.getSignInUserSession()
    if (userSession === null || user === null || !userSession.isValid()) {
        throw new ExpiredSessionError()
    }
    if (mustHaveMFA(user)) {
        if (cognitoUser.preferredMFA === 'NOMFA') {
            try {
                await Auth.setPreferredMFA(cognitoUser, 'TOTP')
            } catch (error) {
                throw new NoMfaError()
            }
        }
        if (user.email_verified === false) {
            await sendVerificationEmail(cognitoUser)
            throw new VerifyEmailError()
        }
    }
    if (user.role_id === ROLE_NO_LOGIN) {
        throw new NoAccessError()
    }
    return true
}

const loaderStyleProps = {
    style: {
        marginLeft: '42%',
        marginTop: '42%',
    },
    width: '5rem',
    height: '5rem',
}

export function App() {
    const [error, setError] = useState(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user: cognitoUser, signOut }: {user: any, signOut: () => void} = useAuthenticator((context) => [context.user])
    const { route } = useAuthenticator(context => [context.route])
    useEffect(() => {
        if (route !== 'authenticated') {
            return
        }
        const guard = async () => {
            const user = await getUser(cognitoUser)
            const shouldRedirect = await middleware({user, cognitoUser})
            if(!shouldRedirect) {
                return
            }
            await handleRedirect({cognitoUser})
        }
        guard().catch((e) => {
            if ([NoAccessError, VerifyEmailError, ExpiredPasswordError].reduce((acc, errorClass) => acc || e instanceof errorClass, false)) {
                setError(e)
            } else {
                console.log(e)
                setError(null)
                signOut()
            }
        })
    }, [route])
    let content = <Loader {...loaderStyleProps} />
    if (error) {
        const signOutBtnAction = () => {setError(null); signOut()}
        if (error instanceof VerifyEmailError) {
            content = <InvalidUserAlert signOut={signOutBtnAction} />
        } else {
            content = <UserAlert signOut={signOutBtnAction} title={error.title} description={error.description} />
        }
    }
    return (
        <main>
            {content}
        </main>
    )
}

export default withAuthenticator(
    App,
    {
        variation: 'default',
        loginMechanisms: ['email'],
        hideSignUp: true,
        services,
        components,
        formFields,
    },
)

