import React, { useEffect } from 'react'
import {
    View,
    Button,
    useAuthenticator,
    Alert,
} from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'

export const ConfirmResetPassword = {
    Header() {
        return <Alert
            variation="info"
            isDismissible={false}
            hasIcon={true}
        >
            <p>{I18n.get('We emailed you – please allow a moment for the email to arrive.')}</p>
        </Alert>
    },
    Footer() {
        useEffect(() => {
            document
                .querySelectorAll<HTMLElement>(
                    '[data-amplify-authenticator-confirmresetpassword] fieldset .amplify-flex:not(.amplify-alert)',
                )
                .forEach((e: HTMLElement) => {
                    e.style.height = '0px'
                    e.style.visibility = 'hidden'
                })
        })
        const { toSignIn } = useAuthenticator()
        return (
            <View textAlign="center">
                <Button
                    variation="link"
                    isFullWidth={true}
                    fontWeight="normal"
                    onClick={toSignIn}
                    size="small">
                    {I18n.get('Back to Sign In')}
                </Button>
            </View>
        )
    },
}

export default ConfirmResetPassword
