import { CognitoUser } from 'amazon-cognito-identity-js'
import { I18n } from 'aws-amplify'
import { ExpiredPasswordError, ExpiredPasswordErrorMessage, ExpiredSessionError, NoAccessError, NoAccessErrorMessage } from '.'

export const ROLE_ADMIN = 50
export const ROLE_MANAGER = 30
export const ROLE_EDITOR = 20
export const ROLE_VIEWER = 10
export const ROLE_UNVERIFIED = 5
export const ROLE_NO_LOGIN = 0

export const roles = [ROLE_NO_LOGIN, ROLE_UNVERIFIED, ROLE_VIEWER, ROLE_EDITOR, ROLE_MANAGER, ROLE_ADMIN] as const
export type ROLES = typeof roles[number]

export interface ApiUser {
  role_id: ROLES,
  email_verified: boolean,
  affiliate_user_id?: string,
  advertiser_user_id?: string,
  admin_user_id?: string,
  agency_user_id?: string,
}

export const CACHE_KEY_PPREFIX = 'webgains-auth-'
export const UNAUTHORIZED_STATUS = 401
export const getTokenPayload = (idToken: string) => {
    const [, payload] = idToken.split('.')
    return JSON.parse(atob(payload))
}

const USER_PATH = '/auth/user'
const getKeyName = (id: string): string => `${CACHE_KEY_PPREFIX}${id}`



export async function getUser(cognitoUser: Pick<CognitoUser, 'getSignInUserSession'>): Promise<ApiUser> {
    const userSession = cognitoUser.getSignInUserSession()

    if (userSession === null || !userSession.isValid()) {
        throw new ExpiredSessionError()
    }
    const idToken = userSession.getIdToken().getJwtToken()
    const { 'custom:platform_user_id': platformApiId } = getTokenPayload(idToken)
    const cacheData = localStorage.getItem(getKeyName(platformApiId))
    if (cacheData !== null) {
        return JSON.parse(cacheData)
    }
    const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_URL}${USER_PATH}`, {
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${idToken}`,
        },
        method: 'GET',
    })
    const jsonData = await response.json().catch(() => ({error: true}))
    const defaultError = new Error(I18n.get('Failed to fetch - please ensure any ad blockers are switched off and try logging in again'))
    if  (jsonData.error) {
        throw defaultError
    }
    if (response.status === UNAUTHORIZED_STATUS) {
        switch (jsonData.detail) {
        case ExpiredPasswordErrorMessage:
            throw new ExpiredPasswordError()
        case NoAccessErrorMessage:
            throw new NoAccessError()
        default:
            throw defaultError
        }
    }
    const apiUserProps = [
        'role_id', 'affiliate_user_id', 'advertiser_user_id', 'admin_user_id', 'agency_user_id', 'email_verified']
    const apiUser: ApiUser = Object.keys(jsonData)
        .filter(key => apiUserProps.includes(key))
        .reduce((obj: { [key: string]: string | number} , key: string) => {
            if (jsonData[key] !== undefined) {
                obj[key] = jsonData[key]
            }
            return obj
        }, {}) as unknown as ApiUser
    localStorage.setItem(getKeyName(platformApiId), JSON.stringify(apiUser))
    return apiUser
}
