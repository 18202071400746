import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export class VerifyEmailError extends LogOutError {
    constructor() {
        super(I18n.get('User must verify email.'))
    }
}

export default VerifyEmailError
