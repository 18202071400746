import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export class NoMfaError extends LogOutError {
    constructor() {
        super(I18n.get('User must set up MFA.'))
    }
}

export default NoMfaError
