import React from 'react'
import { I18n } from 'aws-amplify'
import {
    View, Link, Heading, Text, Table, TableBody, TableRow, TableCell,
} from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AiOutlineMobile, AiOutlineQrcode, AiOutlineLock } from 'react-icons/ai'

const authenticatorLink = ([appName, androidUrl, iosUrl]: string[]) => {
    const or = I18n.get('or')
    const androidLink = <Link
        href={androidUrl}
        target='_blank'>
        {I18n.get('Android')}
    </Link>
    const iosLink = <Link
        href={iosUrl}
        target='_blank'>
        {I18n.get('iOs')}
    </Link>
    return <div key={appName}>
        {`${appName} (`}
        {androidLink}
        {` ${or} `}
        {iosLink}
        {`)`}
        <br/>
    </div>
}

export function SetupTOTPModal() {
    return (
        <View id="setup-totp-modal">
            <Heading level={4}>{I18n.get('To set up MFA, scan the QR code')}</Heading>
            <View className="subheader">
                {I18n.get('As an administrator user of your account,'
                + ' you are required to protect your account so no one else can access it.'
                + ' The Multi-Factor Authentication (MFA) process provides a second layer of security by asking'
                + ' you to enter a code that is generated on your mobile phone every time you log in, so we know it\'s you.')}
            </View>
            <Heading level={5}>{I18n.get('MFA Setup')}</Heading>
            <View className="subheader">
                {I18n.get('Setting up multi-factor authentication is easy and only takes a few steps:')}
            </View>
            <Table
                highlightOnHover={false}
                className="mfasetupsteps"
                size={'small'}
            >
                <TableBody>
                    <TableRow>
                        <TableCell className='mfasetuptdicons'>
                            <Text as="span" fontSize="40px" className='mfaicon'><AiOutlineMobile /></Text>
                        </TableCell>
                        <TableCell>
                            <Heading level={6}>
                                {I18n.get('1 – Download a free authenticator app (to generate the code) such as:')}
                            </Heading>
                            <View className="subheader-small">
                                {
                                    [
                                        [
                                            I18n.get('Google Authenticator'),
                                            // eslint-disable-next-line max-len
                                            "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
                                            "https://apps.apple.com/us/app/google-authenticator/id388497605"
                                        ],
                                        [
                                            I18n.get('Microsoft Authenticator'),
                                            "https://play.google.com/store/apps/details?id=com.azure.authenticator",
                                            "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                                        ],
                                        [
                                            I18n.get('Twilo Authy Authenticator'),
                                            "https://play.google.com/store/apps/details?id=com.authy.authy",
                                            "https://apps.apple.com/us/app/authy/id494168017",
                                        ]
                                    ].map(authenticatorLink)
                                }
                            </View>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='mfasetuptdicons'>
                            <Text as="span" fontSize="40px" className='mfaicon'><AiOutlineQrcode /></Text>
                        </TableCell>
                        <TableCell>
                            <Heading level={6}>{I18n.get('2 – Scan the QR code (on the left)')}</Heading>
                            <View className="subheader-small">
                                {I18n.get('Open your authenticator app on your mobile phone and scan the QR code on this page.'
                            + ' You could also manually add the long code displayed below the QR code.'
                            + ' The QR code will only be visible during the first successful login.'
                            + ' The next time you log in, the QR code won\'t be visible for security reasons.')}
                            </View>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='mfasetuptdicons'>
                            <Text as="span" fontSize="40px" className='mfaicon'><AiOutlineLock /></Text>
                        </TableCell>
                        <TableCell>
                            <Heading level={6}>{I18n.get('3 – Enter the 6-digit code shown in your app')}</Heading>
                            <View className="subheader-small">
                                {I18n.get('You will be asked to enter an authentication code every time you log in.'
                            + ' The 6-digit code displayed in the authenticator app refreshes every 30 seconds.'
                            + ' If the 6-digit code refreshes in the app before you can confirm it on the login page,'
                            + ' please enter the new code instead.')}
                            </View>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </View>
    )
}

export const SetupTOTP = {
    Header() {
        return (
            <Heading
                level={3}
            >
                {I18n.get('Scan the QR code')}
            </Heading>
        )
    },
    Footer: SetupTOTPModal,
}

export default SetupTOTP
