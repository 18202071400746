import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export const ExpiredPasswordErrorMessage = 'password_expired'

export class ExpiredPasswordError extends LogOutError {
    readonly title: string
    readonly description: string

    constructor() {
        const title = I18n.get('Your password has expired.')
        const description = I18n.get('To login, please click on "Forgot your password?" and reset your password.')

        super(`${title} ${description}`)

        this.title = title
        this.description = I18n.get('To login, navigate back to the login page and click on "Forgot your password?" to reset your password.')
    }
}

export default ExpiredPasswordError
