import { CognitoUser } from 'amazon-cognito-identity-js'
import { ExpiredSessionError } from '.'

const VERIFICATION_PATH = '/api/send-verification-email'

export async function sendVerificationEmail(cognitoUser: Pick<CognitoUser, 'getSignInUserSession'>): Promise<Response|null> {
    const userSession = cognitoUser.getSignInUserSession()
    if (userSession === null || !userSession.isValid()) {
        throw new ExpiredSessionError
    }
    const idToken = userSession.getIdToken().getJwtToken()
    return fetch(`${process.env.REACT_APP_PLATFORM_API_URL}${VERIFICATION_PATH}`, {
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${idToken}`,
        },
        method: 'POST',
    })
}
