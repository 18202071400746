import React, { useEffect } from 'react'
import { I18n } from 'aws-amplify'
import {
    View, Link, Heading, Text, Table, TableBody, TableRow, TableCell,
} from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AiOutlineMobile, AiOutlineLock } from 'react-icons/ai'

export function ConfirmSignInModal() {
    const contactSupportText = I18n.get('If you have lost your mobile phone, deleted your authenticator app,'
    + ' or accidentally deleted your Webgains entry in the app, please'
    +' contact support@webgains.com so we can help you generate a new QR login code.')
        .split('support@webgains.com')
    contactSupportText.splice(1, 0, <Link key={0} href="mailto:support@webgains.com" target='_blank'>
        {'support@webgains.com'}
    </Link>)
    return (
        <View id="confirm-sign-in-modal">
            <Heading level={4}>{I18n.get('Please enter your 6-digit code')}</Heading>
            <View className="subheader">
                {I18n.get('As an administrator user of your account,'
                + ' you are required to protect your account so no one else can access it.'
                + ' The Multi-Factor Authentication (MFA) process provides a second layer of security by asking'
                + ' you to enter a code that is generated on your mobile phone every time you log in, so we know it\'s you.')}
            </View>
            <Heading level={5}>{I18n.get('To get your 6-digit code')}</Heading>
            <View className="subheader-small"/>
            <Table highlightOnHover={false} className="mfasetupsteps" size={'small'}>
                <TableBody>
                    <TableRow>
                        <TableCell className='mfasetuptdicons'>
                            <Text as="span" fontSize="40px" className='mfaicon'><AiOutlineMobile /></Text>
                        </TableCell>
                        <TableCell>
                            <Heading level={6}>{I18n.get('1 – Open your authenticator app')}</Heading>
                            <View className="subheader-small">
                                {I18n.get('On your mobile phone, open the authenticator app you used'
                            +' to scan the QR code during your first login.')}
                            </View>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className='mfasetuptdicons'>
                            <Text as="span" fontSize="40px" className='mfaicon'><AiOutlineLock /></Text>
                        </TableCell>
                        <TableCell>
                            <Heading level={6}>{I18n.get('2 – Enter the 6-digit code shown in your app')}</Heading>
                            <View className="subheader-small">
                                {I18n.get('Select the "Webgains" entry and type the 6-digit code displayed'
                            + ' in the authenticator app into the field on the left and click “Confirm”.'
                            + ' If the 6-digit code refreshes in the app before you can confirm it on the login page,'
                            + ' please enter the new code instead.')}
                            </View>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <View className="subheader-small">
                {contactSupportText}
            </View>
        </View>
    )
}

export const ConfirmSignIn = {
    Header() {
        useEffect(() => {
            (document?.querySelector("[name=confirmation_code]") as HTMLElement)?.focus()
        }, [])
        return (
            <Heading
                level={3}
            >
                {I18n.get('Enter the 6-digit code as shown in your app')}
            </Heading>
        )
    },
    Footer: ConfirmSignInModal,
}

export default ConfirmSignIn
