import { AuthOptions } from '@aws-amplify/auth/lib/types';

const config: {Auth: AuthOptions} = {
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
        signUpVerificationMethod: 'code',
        authenticationFlowType: 'USER_PASSWORD_AUTH',

    },
}

export default config
