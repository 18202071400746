import { I18n } from 'aws-amplify'
import LogOutError from './logOutError'

export const NoAccessErrorMessage = 'no_login'

export class NoAccessError extends LogOutError {
    readonly title: string
    readonly description: string

    constructor() {
        const title = I18n.get('Your account has been disabled.')
        const description = I18n.get('If you think this is an error, please contact support@webgains.com.')

        super(`${title} ${description}`)

        this.title = title
        this.description = description
    }
}

export default NoAccessError
