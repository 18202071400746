import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import {
    Flex, View,
} from '@aws-amplify/ui-react'
import App from './app'
import SignOut from './signOut'
import './index.css'

const router = createBrowserRouter([
    {
        path: ':relative?/signout',
        element: <SignOut />,
    },
    {
        path: '/',
        element: <App />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace={true} />,
    },
])

const rootElement = document.getElementById('root')
if (rootElement !== null) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <Flex direction={{ base: 'column', large: 'row' }}>
            <View width="100%">
                <RouterProvider router={router} />
            </View>
            <View width="100%" id="info-panel">
            </View>
        </Flex>,
    )
}
