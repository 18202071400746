export const signIn = {
    username: {
        label: 'Email',
        placeholder: 'Enter your email',
    },
    password: {
        label: 'Password',
        placeholder: 'Enter your password',
    },
}

export default signIn
