const defaultExpiryDate = new Date()
defaultExpiryDate.setTime(defaultExpiryDate.getTime() + (1 * 24 * 60 * 60 * 1000))
export {defaultExpiryDate}

const domain = window.location.hostname.split('.').slice(-2).join('.')

export const setCookie = (name: string, value: string, expiryDate: Date = defaultExpiryDate) => {
    document.cookie = `${name}=${value}; expires=${expiryDate.toUTCString()}; domain=${domain}; path=/`
}

export const deleteCookie = (name: string) => {
    const date = new Date()
    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000))
    document.cookie = `${name}=; expires=${date.toUTCString()}; domain=${domain}; path=/`
}