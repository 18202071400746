import React from 'react'
import {
    Heading,
} from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'

export const ResetPassword = {
    Header() {
        return (
            <Heading
                level={3}
            >
                {I18n.get('Reset your password')}
            </Heading>
        )
    },
}

export default ResetPassword
