import { DefaultTexts } from "@aws-amplify/ui"

const RESET_PASSWORD_PATH = '/api/password/send-reset-email'

export async function handleForgotPassword(email: string): Promise<boolean> {
    const postData = JSON.stringify({
        email,
        redirect_to: `https://${window.location.host}`,
    })
    const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_URL}${RESET_PASSWORD_PATH}`, {
        headers: {
            'content-type': 'application/json',
            'content-length': postData.length.toString(),
        },
        body: postData,
        method: 'POST',
    })
    const jsonData = await response.json()
    if (jsonData.success) {
        return true
    } else if (jsonData.error) {
        throw new Error(jsonData.error)
    } else {
        throw new Error(DefaultTexts.CODE_ARRIVAL)
    }
}

export default handleForgotPassword
