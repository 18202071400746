import React from 'react'
import { I18n } from 'aws-amplify'
import {
    View, Heading, Button, Image
} from '@aws-amplify/ui-react'

const invalidUserAlert = ({signOut}) => {
    return <View id="verify-email-modal" style={{left: '5vw', top: '1vh', backgroundColor: 'white'}}> 
        <View textAlign="center">
            <Image
                alt="Webgains logo"
                src="https://www.webgains.com/public/wp-content/themes/webgains-updated/img/logo-new-color-s100.svg"
                width="100px"
                height="100px"
                style={{margin: '0 0 5vh 0'}}
            />
        </View >
        <Heading level={3} textAlign="center">{I18n.get('We need to verify your email address')}</Heading>
        <View className="subheader" textAlign="center">
            {
                // eslint-disable-next-line max-len
                I18n.get('Webgains needs more information to keep your account secure. We therefore need to verify the email address associated with your account before you can log in.')
            }
        </View>
        <View className="subheader" textAlign="center">
            {
                // eslint-disable-next-line max-len
                I18n.get('An email has been sent to confirm your email address. Please follow the instructions in that email to verify your account and continue to log in.')
            }
        </View>
        <View textAlign="center">
            <Button
                variation="primary"
                isFullWidth={true}
                fontWeight="normal"
                onClick={signOut}
                size="small">
                {I18n.get('Back to Sign In')}
            </Button>
        </View>
    </View>

}

export default invalidUserAlert
