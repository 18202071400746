import { useTheme, View, Image } from '@aws-amplify/ui-react'
import React from 'react'

export function Header() {
    const { tokens } = useTheme()
    return (
        <View textAlign="center" padding={tokens.space.large}>
            <Image
                alt="Webgains logo"
                src="https://www.webgains.com/public/wp-content/themes/webgains-updated/img/logo-new-color-s100.svg"
                width="100px"
                height="100px"
            />
        </View>
    )
}

export default Header
