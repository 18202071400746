import React, { useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Auth } from '@aws-amplify/auth'
import { useNavigate } from 'react-router-dom'
import { Authenticator } from '@aws-amplify/ui-react'

const SignOut = () => {
    const navigate = useNavigate()
    useEffect(() => {
        Auth.signOut().then(() => {
            navigate('/')
        })
    }, [])
    return null
}

const WrappedSigOut = () => (
    <Authenticator.Provider>
        <SignOut />
    </Authenticator.Provider>
)

export default WrappedSigOut
